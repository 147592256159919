import { LinkOutlined } from '@ant-design/icons'
import { Button, Table, Tooltip } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { t } from 'i18next'
import moment from 'moment'
import { AlignType } from 'rc-table/lib/interface'
import Moment from 'react-moment'
import styled from 'styled-components'
import { dateFormat, privacyTypes } from '../constants/constants'
import SimpleSwitch from './SimpleSwitch'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import TableBoldColumnContainer from './display/TableBoldColumnContainer'
import { SortOrder, TableCurrentDataSource } from 'antd/lib/table/interface'
import { BackEndSortOrder } from '../types/generalTypes'

const FirstLineContainer = styled.div`
  margin: 0 8px 4px 8px;
`

const SecondLineContainer = styled.div`
  font-size: 12px;
  margin: 0 8px 0 8px;
`

const LanguageContainer = styled.div`
  text-transform: capitalize;
`

export interface SearchTableRow {
  id: string
  key: string
  title?: string
  publicationName?: string
  authors?: string[]
  publicationDate?: string
  included?: boolean
  documentLink?: string
  language?: string
}

interface SearchTableProps {
  formattedData: SearchTableRow[]
  useInclusionToggle?: boolean
  onSwitchClick?: (recordId: string) => void
  loadingIds?: string[]
  onClick?: (recordId: string) => void
  maxHeight?: string
  usePagination?: true
  currentPage?: number
  setCurrentPage?: (page: number) => void
  refreshData?: (
    page: number,
    sortField?: string,
    sortOrder?: BackEndSortOrder
  ) => void
  totalResult?: string | number
  useBackEndSort?: boolean
  currentSort?: string
  dataSourceVisibility?: string
}

const SearchTable = ({
  formattedData,
  useInclusionToggle,
  onSwitchClick,
  loadingIds,
  onClick,
  maxHeight,
  usePagination,
  currentPage,
  refreshData,
  totalResult,
  useBackEndSort,
  currentSort,
  dataSourceVisibility,
}: SearchTableProps) => {
  const renderAuthors = (record: SearchTableRow) => (
    <>
      <FirstLineContainer>
        {record.publicationName || t('searchTable.noJournalAvailable')}
      </FirstLineContainer>
      <SecondLineContainer>
        {record.authors?.join(', ') || t('searchTable.noAuthorAvailable')}
      </SecondLineContainer>
    </>
  )

  const columns: ColumnsType<SearchTableRow> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '35%',
      render: (text: string) => (
        <TableBoldColumnContainer data-testid="search-table-title">
          {text || t('searchTable.noTitleAvailable')}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('searchTable.publicationTitle'),
      dataIndex: 'authors',
      key: 'authors',
      width: '35%',
      sortDirections: ['ascend'] as SortOrder[],
      sorter: useBackEndSort
        ? (a: SearchTableRow, b: SearchTableRow) =>
            a.publicationName && b.publicationName
              ? a.publicationName.localeCompare(b.publicationName)
              : -1
        : undefined,
      sortOrder:
        useBackEndSort &&
        dataSourceVisibility?.toLocaleUpperCase() === privacyTypes.PUBLIC &&
        currentSort &&
        (currentSort as SorterResult<SearchTableRow>).field === 'authors'
          ? (currentSort as SorterResult<SearchTableRow>).order
          : undefined,
      render: (_: string, record: SearchTableRow) => renderAuthors(record),
    },
    {
      title: t('searchTable.publicationDate'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      width: useInclusionToggle ? '15%' : '20%',
      sortDirections: ['descend'] as SortOrder[],
      sorter: useBackEndSort
        ? (a: SearchTableRow, b: SearchTableRow) =>
            a.publicationDate && b.publicationDate
              ? moment(a.publicationDate).valueOf() -
                moment(b.publicationDate).valueOf()
              : -1
        : undefined,
      sortOrder:
        useBackEndSort &&
        dataSourceVisibility?.toLocaleUpperCase() === privacyTypes.PUBLIC &&
        currentSort &&
        (currentSort as SorterResult<SearchTableRow>).field ===
          'publicationDate'
          ? (currentSort as SorterResult<SearchTableRow>).order
          : undefined,
      render: (_: string, record: SearchTableRow) => (
        <>
          <FirstLineContainer>
            {record.publicationDate ? (
              <Moment local format={dateFormat.PRIMARY}>
                {record.publicationDate}
              </Moment>
            ) : (
              t('searchTable.noDateAvailable')
            )}
          </FirstLineContainer>
          <SecondLineContainer>
            <LanguageContainer>
              {record.language || t('searchTable.noLanguageAvailable')}
            </LanguageContainer>
          </SecondLineContainer>
        </>
      ),
    },
    {
      title: (
        <>
          {t('searchTable.include')}/<br />
          {t('searchTable.exclude')}
        </>
      ),
      dataIndex: 'includeExclude',
      key: 'includeExclude',
      width: '10%',
      render: (_: string, record: SearchTableRow) => (
        <SimpleSwitchContainer>
          <SimpleSwitch
            data-testid={`simple-switch-${record.id}`}
            checked={record.included}
            onClick={() => onSwitchClick && onSwitchClick(record.id)}
            loading={loadingIds?.includes(record.id)}
          />
          <div data-testid={`simple-switch-text-${record.id}`}>
            {record.included ? t('Included') : t('Excluded')}
          </div>
        </SimpleSwitchContainer>
      ),
    },
    {
      title: t('Link'),
      key: 'action',
      dataIndex: 'action',
      align: 'right' as AlignType,
      width: 20,
      render: (_: string, record: SearchTableRow) => (
        <Tooltip
          title={t('searchTable.linkTooltip')}
          placement="left"
          align={{ offset: [-24, 0] }}
        >
          <Button
            type="default"
            icon={<LinkOutlined />}
            href={record.documentLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          />
        </Tooltip>
      ),
    },
  ].filter((col) =>
    useInclusionToggle ? true : col.dataIndex !== 'includeExclude'
  )

  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SearchTableRow> | SorterResult<SearchTableRow>[],
    extra: TableCurrentDataSource<SearchTableRow>
  ) => {
    if (refreshData) {
      refreshData(
        pagination.current || 0,
        useBackEndSort
          ? (sorter as SorterResult<SearchTableRow>).field?.toString()
          : undefined,
        useBackEndSort
          ? (sorter as SorterResult<SearchTableRow>).order === 'descend'
            ? 'DESC'
            : 'ASC'
          : undefined
      )
    }
  }

  return (
    <Table
      rowClassName="page-list-table-row clickable"
      columns={columns}
      dataSource={formattedData}
      onChange={handleOnChange}
      pagination={
        usePagination
          ? {
              current: currentPage,
              total: Number(totalResult),
              showSizeChanger: false,
              pageSize: 100,
              position: ['bottomLeft'],
            }
          : false
      }
      scroll={{ y: maxHeight }}
      onRow={(record, rowIndex) => ({
        'data-testid': `searchTableRow${rowIndex}`,
        onClick: (event) => {
          onClick && onClick(record.id)
        }, // click row
      })}
    />
  )
}

export default SearchTable
