import { ReadOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputRef, Radio } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  FilterBadge,
  ModalBase,
  SelectLiteratureReview,
} from '../../../components'
import InfoSection from '../../../components/display/InfoSection'
import { useLiteratureReviews } from '../../../hooks'
import literatureReviewService, {
  SavingType,
  savingTypes,
} from '../../../services/literatureReviewService'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import {
  LiteratureReview,
  setSelectedLiteratureReview,
} from '../../../store/reducers/literatureReviewReducer'
import { SearchMethod } from '../../../types/generalTypes'
import {
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from '../../LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import { routePaths } from '../../RootPage'

const TabContainer = styled.div`
  margin: 8px 0 16px 0;
`

const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`
interface NewTabProps {
  inputContent: string
  setInputContent: (content: string) => void
}

const NewTab = ({ inputContent, setInputContent }: NewTabProps) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Form layout="vertical" requiredMark={false}>
      <Form.Item
        label={t('searchPage.createLiteratureReviewModal.newReviewLabel')}
        required
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
        ]}
        extra={t('searchPage.createLiteratureReviewModal.newReviewExtra')}
        name="title"
      >
        <Input
          placeholder={
            t('searchPage.createLiteratureReviewModal.inputPlaceholder') || ''
          }
          ref={inputRef}
          value={inputContent}
          onChange={(event) => setInputContent(event.target.value)}
        />
      </Form.Item>
    </Form>
  )
}

interface ExistingTabProps {
  setLiteratureReview: (literatureReview: LiteratureReview | undefined) => void
}

const ExistingTab = ({ setLiteratureReview }: ExistingTabProps) => (
  <Form layout="vertical">
    <Form.Item
      label={t('searchPage.createLiteratureReviewModal.existingReviewLabel')}
      extra={t('searchPage.createLiteratureReviewModal.existingReviewExtra')}
    >
      <SelectLiteratureReview
        style={{ width: '200px' }}
        onChange={setLiteratureReview}
      />
    </Form.Item>
  </Form>
)

export interface CreateLiteratureReviewModalProps {
  query?: string
  searchMethod?: SearchMethod
  sourceId?: string
  period?: string
  total?: number
  disabled?: boolean
}

const CreateLiteratureReviewModal = ({
  query,
  sourceId,
  period,
  total,
  searchMethod,
  disabled,
}: CreateLiteratureReviewModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [savingType, setSavingType] = useState<SavingType>(savingTypes.NEW)
  const [reviewTitle, setReviewTitle] = useState('')
  const [literatureReview, setLiteratureReview] = useState<
    LiteratureReview | undefined
  >(undefined)
  const source = useSelector((state: RootState) =>
    state.dataSource.dataSourceList.find((source) => source.id === sourceId)
  )

  const { refreshLiteratureReviewList } = useLiteratureReviews({
    preventFetch: true,
  })

  const handleOk = () => {
    setConfirmLoading(true)
    literatureReviewService
      .saveSearchQueryInLiteratureReview({
        type: savingType,
        navigate: navigate,
        searchQuery: query,
        searchMethod,
        sourceId,
        period,
        savingType,
        title: reviewTitle,
        literatureReviewId: literatureReview?.id,
      })
      .then((response: LiteratureReview) => {
        dispatch(setSelectedLiteratureReview(response))
        setOpen(false)
        setConfirmLoading(false)
        refreshLiteratureReviewList()
        navigate(
          `${routePaths.LITERATURE_REVIEW_DETAILS}/${response.id}/${
            literatureReviewPanelTypes.OVERVIEW
          }/${
            savingType === savingTypes.NEW
              ? literatureReviewTabs.INFO
              : literatureReviewTabs.QUERIES
          }`
        )
        notificationService.notificationSuccess(
          t(
            'searchPage.createLiteratureReviewModal.successAddSearchQueryToLiteratureReview',
            {
              searchQuery: query,
              literatureReviewTitle: response.title,
            }
          )
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setOpen(false)
        setConfirmLoading(false)
      })
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleLiteratureReview = (value?: LiteratureReview) => {
    setLiteratureReview(value)
  }

  const tabs = {
    NEW: <NewTab inputContent={reviewTitle} setInputContent={setReviewTitle} />,
    EXISTING: <ExistingTab setLiteratureReview={handleLiteratureReview} />,
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        icon={<ReadOutlined />}
      >
        {t('searchPage.createLiteratureReviewModal.openModalButton')}
      </Button>
      <ModalBase
        destroyOnClose
        title={t('searchPage.createLiteratureReviewModal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} type="text">
            {t('Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            icon={<ReadOutlined />}
            onClick={handleOk}
            loading={confirmLoading}
          >
            {savingType === savingTypes.NEW
              ? t('searchPage.createLiteratureReviewModal.submitButtonNew')
              : t(
                  'searchPage.createLiteratureReviewModal.submitButtonExisting'
                )}
          </Button>,
        ]}
      >
        <TabContainer>
          <Radio.Group
            defaultValue={savingTypes.NEW}
            size="large"
            buttonStyle="solid"
          >
            <Radio.Button
              value={savingTypes.NEW}
              onClick={() => setSavingType(savingTypes.NEW)}
            >
              {t('New')}
            </Radio.Button>
            <Radio.Button
              value={savingTypes.EXISTING}
              onClick={() => setSavingType(savingTypes.EXISTING)}
            >
              {t('Existing')}
            </Radio.Button>
          </Radio.Group>
        </TabContainer>
        {tabs[savingType]}
        <InfoSection
          label={t('searchPage.createLiteratureReviewModal.includedQueryLabel')}
        >
          <div>{query}</div>
          <BadgesContainer>
            {source?.title && (
              <FilterBadge>
                {source.typeCode}: {source?.title}
              </FilterBadge>
            )}
            {period && <FilterBadge>{period}</FilterBadge>}
          </BadgesContainer>
          <div>{t('searchPage.totalResult', { count: total })}</div>
        </InfoSection>
      </ModalBase>
    </>
  )
}

export default CreateLiteratureReviewModal
