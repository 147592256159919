import { NavigateFunction } from 'react-router-dom'
import { dataTypes, urlList } from '../constants/constants'
import i18n from '../i18n/i18n'
import { SearchSource } from '../store/reducers/literatureReviewReducer'
import { SearchMethod } from '../types/generalTypes'
import apiService from './apiService'

export type SavingTypeDictionary = { [tabKey: string]: SavingType }

export const savingTypes: SavingTypeDictionary = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
}

export type SavingType = 'NEW' | 'EXISTING'

interface SearchParameters {
  dataType: string[]
  date: string
  language: string
  searchMethod: string
  searchQuery: string
  source: string
}

interface SearchBody {
  searchDescription: string
  searchParameters: SearchParameters
}

interface SaveSearchQueryInLiteratureReviewProps {
  type: SavingType
  navigate: NavigateFunction
  searchQuery?: string
  searchMethod?: SearchMethod
  sourceId?: string
  period?: string
  savingType?: SavingType
  title?: string
  literatureReviewId?: string
}

const saveSearchQueryInLiteratureReview = async ({
  type,
  navigate,
  searchQuery,
  searchMethod,
  sourceId,
  period,
  savingType,
  title,
  literatureReviewId,
}: SaveSearchQueryInLiteratureReviewProps) => {
  const searchBody: SearchBody = {
    searchDescription: searchQuery || '',
    searchParameters: {
      dataType: [dataTypes.DOCUMENT],
      date: period || '',
      language: i18n.language,
      searchMethod: searchMethod || '',
      searchQuery: searchQuery || '',
      source: sourceId || '',
    },
  }

  const params = {
    searchBody,
    title: savingType === savingTypes.NEW ? title : undefined,
  }

  if (type === savingTypes.NEW) {
    return apiService
      .saveItem(urlList.LITERATURE_REVIEWS, navigate, params)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error('axios save search error', error)
        throw error
      })
  } else {
    return apiService
      .updateItem(
        `${urlList.LITERATURE_REVIEWS}/${literatureReviewId}/newQuery`,
        navigate,
        params
      )
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error('axios save search error', error)
        throw error
      })
  }
}

interface SaveSearchQueryInclusion {
  literatureReviewId: string
  includedSearchResults: string[]
  recordId: string
  navigate: NavigateFunction
}

const saveSearchQueryInclusion = async ({
  literatureReviewId,
  includedSearchResults,
  recordId,
  navigate,
}: SaveSearchQueryInclusion) => {
  let includedList = includedSearchResults

  if (includedList?.includes(recordId)) {
    includedList = includedList.filter(
      (searchResult) => searchResult !== recordId
    )
  } else {
    includedList = [...includedList, recordId]
  }

  const params = {
    includedSearchResults: includedList,
  }

  return apiService
    .updateItem(
      `${urlList.LITERATURE_REVIEWS}/${literatureReviewId}`,
      navigate,
      params
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      apiService.notificationError(error)
      console.error('axios save search error', error)
      throw error
    })
}

interface UpdateLiteratureReviewDetailsProps {
  title: string
  description: string
  literatureReviewId: string
  navigate: NavigateFunction
}

const updateLiteratureReviewDetails = async ({
  title,
  description,
  literatureReviewId,
  navigate,
}: UpdateLiteratureReviewDetailsProps) => {
  const params = {
    title,
    description,
  }

  return apiService
    .updateItem(
      `${urlList.LITERATURE_REVIEWS}/${literatureReviewId}`,
      navigate,
      params
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios search error', error)
      throw error
    })
}

interface DeleteLiteratureReviewProps {
  literatureReviewId: string
  navigate: NavigateFunction
}

const deleteLiteratureReview = async ({
  literatureReviewId,
  navigate,
}: DeleteLiteratureReviewProps) => {
  return apiService
    .deleteItem(`${urlList.LITERATURE_REVIEWS}/${literatureReviewId}`, navigate)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios delete error', error)
      throw error
    })
}

const getSearchQuerySourceId = (
  searchQuery: string | SearchSource | undefined
) => {
  if (typeof searchQuery === 'string') {
    return searchQuery
  } else if (searchQuery !== undefined) {
    return searchQuery.id
  }
  return ''
}

const exportLiteratureReviewList = async () => {
  return apiService
    .fetchItems(`${urlList.LITERATURE_REVIEWS}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportLiteratureReviewToCsvProps {
  literatureReviewId: string
}

const exportLiteratureReviewToCsv = async ({
  literatureReviewId,
}: ExportLiteratureReviewToCsvProps) => {
  return apiService
    .fetchItems(`${urlList.LITERATURE_REVIEWS}/${literatureReviewId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportLiteratureReviewSearchQueryToCsvProps {
  literatureReviewId: string
  searchQueryId: string
}

const exportLiteratureReviewSearchQueryToCsv = async ({
  literatureReviewId,
  searchQueryId,
}: ExportLiteratureReviewSearchQueryToCsvProps) => {
  return apiService
    .fetchItems(
      `${urlList.LITERATURE_REVIEWS}/${literatureReviewId}/searchId=${searchQueryId}/csv`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const literatureReviewService = {
  deleteLiteratureReview,
  exportLiteratureReviewList,
  exportLiteratureReviewSearchQueryToCsv,
  exportLiteratureReviewToCsv,
  getSearchQuerySourceId,
  saveSearchQueryInclusion,
  saveSearchQueryInLiteratureReview,
  updateLiteratureReviewDetails,
}

export default literatureReviewService
