import { ReactNode } from 'react'
import { Tag } from 'antd'
import { green } from '@ant-design/colors'

export interface BadgeProps {
  children?: ReactNode
}

const FilterBadge = ({ children }: BadgeProps) => {
  return (
    <Tag
      style={{
        backgroundColor: green[0],
        margin: 0,
        border: '1px solid var(--green-3, #B7EB8F)',
        color: green[5], // --green-6
      }}
    >
      {children}
    </Tag>
  )
}

export default FilterBadge
